
export const TAGKEYS = {
  FEATURE_TYPE: 'utto#featureType',
  UTILITY_TYPE: 'utto#utilityType',
};

const validUtils = [
  'other',
  'electric',
  'gas',
  'oil',
  'comms',
  'water',
  'reclaimed',
  'sewer',
  'road',
];

// split '<key>:<value' formatted string,
// returning array of [ key, value ]
const splitKeyValue = s => {
  const idx = s.indexOf(':');
  if (idx < 0) return [ '', s ];
  return [ s.slice(0,idx), s.slice(idx+1) ];
};

export const getTagValue = (tags, key) => {
  if (!tags) return null;
  const v = tags.reverse().find(x => x.startsWith(key+':'));
  return v ? splitKeyValue(v)[1] : null;
}

export const getUtilityType = (tags) => {
  const v = getTagValue(tags, TAGKEYS.UTILITY_TYPE);
  if (v == null) return validUtils[0];
  const t = validUtils.find(x => x === v.toLowerCase());
  return t ? t : validUtils[0];
}
